import apiErrors from '@/configs/apiErrors';
import inputErrors from '@/configs/inputErrors';
import { useErrorsStore } from '@/stores/ErrorsStore.js';
import { useModalStore } from '@/stores/ModalStore.js';

export const getErrorMessage = (error, defaultError) => {
  if (Object.keys(apiErrors).includes(error.toString())) {
    return apiErrors[error];
  }

  return {
    message: defaultError,
    type: 'error'
  };
};

export const getInputErrorMessage = (error, defaultError) => {
  if (Object.keys(inputErrors).includes(error.toString())) {
    return inputErrors[error];
  }

  return {
    message: defaultError,
    type: 'error'
  };
};

export const getAllRoutes = (routes, result = []) => {
  routes.forEach((route) => {
    if (route.name || route.path || (route.meta && Object.keys(route.meta).length)) {
      const routeInfo = {
        ...(route.name && { name: route.name }),
        ...(route.meta && { meta: route.meta }),
        ...(route.path && { path: route.path })
      };
      result.push(routeInfo);
    }

    if (route.children && route.children.length) {
      getAllRoutes(route.children, result);
    }
  });
  return result;
};

export const serverErrorsHandler = (newErrors, pageName, form) => {
  const errorsState = useErrorsStore();
  const modals = useModalStore();
  errorsState.setErrors(newErrors, pageName);

  const allErrors = Object.entries(errorsState.errors[pageName]);

  allErrors.forEach(([key, value]) => {
    const profilePasswordsKeywords = [
      'current_password',
      'new_password',
      'new_password_confirmation'
    ];

    if (!pageName && !form) {
      modals.openToast({ type: 'error', text: value[0] });
    }

    const name = profilePasswordsKeywords.includes(key) ? 'profile_passwords' : pageName;
    const input = form[name][key];

    if (input) {
      input.serverError = value[0];
    } else {
      modals.openToast({ type: 'error', text: value[0] });
    }
  });
};

export default {
  getErrorMessage,
  getInputErrorMessage,
  getAllRoutes
};
