import { defineStore } from 'pinia';
import { useMainStore } from './MainStore';
import { useTestsStore } from '@/stores/TestsStore.js';
import { ref, computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { API } from '@/api/index';
import { v4 as uuidv4 } from 'uuid';
import { useSwitchLanguage } from '@/composables/useSwitchLanguage.js';
import { useLoading } from '@/composables/useLoading.js';
import { serverErrorsHandler } from '@/helpers/utils.js';
export const useAuthStore = defineStore('AuthStore', () => {
  const router = useRouter();
  const testsStore = useTestsStore();
  const mainStore = useMainStore();
  const { switchLanguage } = useSwitchLanguage();
  const { isLoading, setLoaderState } = useLoading();
  const user = reactive({
    name: '',
    email: '',
    role: 'basic',
    paid: JSON.parse(localStorage.getItem('isPaid')),
    language: null,
    timezone: null,
    id: null,
    payment_expired_at: null
  });
  const userSettings = reactive({
    data: {
      name: { value: '' },
      language: { value: '' }
    },
    passwords: {
      current_password: {
        value: '',
        error: '',
        serverError: ''
      },
      new_password: {
        value: '',
        error: '',
        serverError: ''
      },
      new_password_confirmation: {
        value: '',
        error: '',
        serverError: ''
      }
    }
  });
  const profileName = ref('');

  const token = ref(localStorage.getItem('token'));
  const isLoggedIn = computed(() => !!token.value);

  const inputs = reactive({
    signIn: {
      email: {
        key: 'email',
        value: '',
        page: 'signIn',
        error: '',
        serverError: '',
        valid: true
      },
      password: {
        key: 'password',
        value: '',
        hide: true,
        page: 'signIn',
        error: '',
        serverError: '',
        valid: true
      }
    },
    signUp: {
      name: {
        key: 'name',
        value: '',
        page: 'signUp',
        error: '',
        serverError: '',
        valid: true
      },
      email: {
        key: 'email',
        value: '',
        page: 'signUp',
        error: '',
        serverError: '',
        valid: true
      },
      password: {
        key: 'password',
        value: '',
        hide: true,
        page: 'signUp',
        error: '',
        serverError: '',
        valid: true
      },
      password_confirmation: {
        key: 'password_confirmation',
        value: '',
        hide: true,
        page: 'signUp',
        error: '',
        serverError: '',
        valid: true
      }
    },
    forgotPassword: {
      email: {
        key: 'email',
        value: '',
        page: 'forgotPassword',
        error: '',
        serverError: '',
        valid: true
      }
    },
    changePassword: {
      password: {
        key: 'password',
        value: '',
        hide: true,
        page: 'changePassword',
        error: '',
        serverError: '',
        valid: true
      },
      password_confirmation: {
        key: 'password_confirmation',
        value: '',
        hide: true,
        page: 'changePassword',
        error: '',
        serverError: '',
        valid: true
      }
    },
    profile: {
      name: {
        key: 'name',
        value: computed({
          get: () => profileName.value,
          set: (newValue) => {
            profileName.value = newValue;
          }
        }),
        page: 'profile',
        error: '',
        serverError: '',
        valid: true
      },
      timezone: {
        key: 'timezone',
        value: computed({
          get: () => user.timezone,
          set: (newValue) => {
            user.timezone = newValue;
          }
        }),
        page: 'profile',
        error: '',
        serverError: '',
        valid: true
      },
      language: {
        key: 'language',
        value: computed({
          get: () => user.language,
          set: (newValue) => {
            user.language = newValue;
          }
        }),
        page: 'profile',
        error: '',
        serverError: '',
        valid: true
      }
    },
    profile_passwords: {
      current_password: {
        key: 'current_password',
        value: '',
        hide: true,
        page: 'changePassword',
        error: '',
        serverError: '',
        valid: true
      },
      new_password: {
        key: 'new_password',
        value: '',
        hide: true,
        page: 'changePassword',
        error: '',
        serverError: '',
        valid: true
      },
      new_password_confirmation: {
        key: 'new_password_confirmation',
        value: '',
        hide: true,
        page: 'changePassword',
        error: '',
        serverError: '',
        valid: true
      }
    }
  });

  const saveEnabled = computed(() => {
    const data = Object.entries(userSettings.data);
    const password = Object.entries(userSettings.passwords);
    const dataChanged = data.some(
      ([key, _]) => inputs.profile[key].value !== userSettings.data[key]
    );
    const passwordChanged = password.some(
      ([key, _]) => inputs.profile_passwords[key].value !== userSettings.passwords[key].value
    );
    return dataChanged || passwordChanged;
  });

  const resetProfileValues = () => {
    const data = Object.entries(userSettings.data);
    data.forEach(([key, value]) => {
      inputs.profile[key] = {
        ...inputs.profile[key],
        value
      };
    });

    const passwords = Object.entries(userSettings.passwords);
    passwords.forEach(([key, value]) => {
      inputs.profile_passwords[key] = {
        ...inputs.profile_passwords[key],
        ...value
      };
    });
  };
  const getUserProfile = async () => {
    setLoaderState(true);

    try {
      const {
        data: { data },
        status
      } = await API.auth.getProfile();

      if (status === 200) {
        for (const [key, value] of Object.entries(data)) {
          user[key] = value;
        }
        profileName.value = data.name;
        userSettings.data.name = data.name;
        userSettings.data.language = data.language;
        inputs.profile.name.value = data.name;
        inputs.profile.language.value = data.language;
        inputs.profile.timezone.value = data.timezone;
        mainStore.getMoods();
        if (!testsStore.tests.length) testsStore.getAllTests();
        testsStore.getTotalResult(data.click_id);
        mainStore.getArticlesContent();
        switchLanguage(data.language);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderState(false);
    }
  };
  const signIn = async (
    credentials = { email: inputs.signIn.email.value, password: inputs.signIn.password.value }
  ) => {
    setLoaderState(true);

    const payload = {
      email: credentials.email,
      password: credentials.password,
      device_id: uuidv4()
    };

    try {
      const {
        data: { data },
        status
      } = await API.auth.authorization(payload);

      if (status === 200) {
        const subscriptionActive = data.role === 'basic';
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('isPaid', subscriptionActive);
        user.paid = subscriptionActive;
        Object.keys(data).forEach((key) => {
          user[key] = data[key];
        });
        token.value = data.access_token;
        await getUserProfile();
        router.push({ name: 'home' });
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 422) {
        serverErrorsHandler(error.response.data.errors, 'signIn', inputs);
      }
    } finally {
      setLoaderState(false);
    }
  };

  const socialiteLogin = async ({ code }) => {
    setLoaderState(true);

    const body = {
      code,
      device_id: uuidv4()
    };

    const {
      data: { data },
      status
    } = await API.auth.socialiteLogin({ socialite: 'google', body });

    if (status === 200) {
      const subscriptionActive = data.role === 'basic';
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('isPaid', subscriptionActive);
      user.paid = subscriptionActive;
      Object.keys(data).forEach((key) => {
        user[key] = data[key];
      });
      token.value = data.access_token;
      await getUserProfile();
      router.push({ name: 'home' });
    }
  };

  const socialiteRegister = async ({ code, expired_at, timezone }) => {
    setLoaderState(true);

    const body = {
      code,
      timezone,
      expired_at
    };

    const {
      data: { data },
      status
    } = await API.auth.socialiteRegister({ socialite: 'google', body });

    if (status === 200 || status === 201) {
      const subscriptionActive = data.role === 'basic';
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('isPaid', subscriptionActive);
      user.paid = subscriptionActive;
      Object.keys(data).forEach((key) => {
        user[key] = data[key];
      });
      token.value = data.access_token;

      await getUserProfile();
      router.push({ name: 'home' });
    }
  };

  const resetPassword = async (token) => {
    setLoaderState(true);

    const payload = {
      token,
      password: inputs.changePassword.password.value,
      password_confirmation: inputs.changePassword.password_confirmation.value
    };

    try {
      const { status } = await API.auth.resetPassword(payload);

      if (status === 200 || status === 204) {
        router.push({ name: 'sign-in' });
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 422) {
        serverErrorsHandler(error.response.data.errors, 'changePassword', inputs);
      }
    } finally {
      setLoaderState();
    }
  };

  const signUp = async (click_id) => {
    setLoaderState(true);

    const payload = {
      click_id,
      name: inputs.signUp.name.value,
      email: inputs.signUp.email.value,
      password: inputs.signUp.password.value,
      password_confirmation: inputs.signUp.password_confirmation.value,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone.replace('Kiev', 'Kyiv')
    };

    try {
      const {
        data: { data },
        status
      } = await API.auth.registration(payload);

      if (status === 200 || status === 201) {
        const subscriptionActive = data.role === 'basic';
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('isPaid', subscriptionActive);
        user.paid = subscriptionActive;
        Object.keys(data).forEach((key) => {
          user[key] = data[key];
        });
        token.value = data.access_token;

        await getUserProfile();
        router.push({ name: 'home' });
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 422) {
        serverErrorsHandler(error.response.data.errors, 'signUp', inputs);
      }
    } finally {
      setLoaderState(false);
    }
  };
  const forgotPassword = async () => {
    setLoaderState(true);

    const payload = {
      email: inputs.forgotPassword.email.value
    };

    try {
      const { status } = await API.auth.restorePassword(payload);
      if (status === 200 || status === 204) {
        return true;
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 422) {
        serverErrorsHandler(error.response.data.errors, 'forgotPassword', inputs);
      }
    } finally {
      setLoaderState(false);
    }
  };
  const updateProfileInfo = async (payload) => {
    setLoaderState(true);

    try {
      const {
        data: { data },
        status
      } = await API.auth.updateProfile(payload);
      if (status === 200) {
        Object.keys(data).forEach((key) => {
          user[key] = data[key];
        });
        switchLanguage(data.language);
        inputs.profile_passwords.current_password.value = '';
        inputs.profile_passwords.new_password.value = '';
        inputs.profile_passwords.new_password_confirmation.value = '';
        getUserProfile();
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 422) {
        serverErrorsHandler(error.response.data.errors, 'profile', inputs);
      }
    } finally {
      setLoaderState(false);
    }
  };
  const logout = () => {
    localStorage.removeItem('token');
    token.value = null;
    router.push({ name: 'auth', params: { type: 'sign-in' } });
  };

  return {
    token,
    isLoggedIn,
    user,
    isLoading,
    inputs,
    userSettings,
    saveEnabled,
    signIn,
    signUp,
    forgotPassword,
    updateProfileInfo,
    resetProfileValues,
    logout,
    getUserProfile,
    resetPassword,
    socialiteLogin,
    socialiteRegister
  };
});
