<template>
  <div class="base-range-input">
    <div
      v-if="options.min.name && options.max.name"
      class="base-range-input__values"
    >
      <p class="base-range-input__values-min body body-md">{{ options.min.name }}</p>
      <p class="base-range-input__values-max body body-md">{{ options.max.name }}</p>
    </div>
    <div class="base-range-input__wrapper">
      <input
        v-model="filledWidthValue"
        type="range"
        :min="options.min.count"
        :max="options.max.count * 100"
        :step="1"
        class="base-range-input__input"
        @input="handleInput($event.target.value)"
      />
      <span
        class="filled"
        :style="{ width: `${fill}%` }"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const { options } = defineProps({
  options: {
    type: Object,
    required: true
  },
  mood: {
    type: Number,
    required: true
  }
});

const filledWidthValue = ref(0);

const emit = defineEmits(['update:mood']);

const fill = computed(() => {
  const full = options.max.count * 100;
  return Math.floor((filledWidthValue.value / full) * 100);
});

const handleInput = (value) => {
  const id = Math.round(value / 100);
  emit('update:mood', id);
};
</script>
<style lang="scss" scoped>
.base-range-input {
  width: 100%;

  &__wrapper {
    position: relative;
    height: 4px;
  }

  &__input {
    all: unset;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    border: none;

    &::-moz-range-track {
      height: 4px;
      background: rgba(255, 255, 255, 0.3);
      box-shadow: 0px 5px 15px 0px rgba(120, 120, 141, 0.2);
      border-radius: 5px;
    }

    &::-webkit-slider-runnable-track {
      height: 4px;
      background: rgba(255, 255, 255, 0.3);
      box-shadow: 0px 5px 15px 0px rgba(120, 120, 141, 0.2);
      border-radius: 5px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 32px;
      height: 32px;
      opacity: 0.9;
      background: url('@/assets/img/global/range-icon.png');
      border-radius: 50%;
      -webkit-box-shadow: 0px 0px 57px -4px rgba(34, 60, 80, 0.5);
      -moz-box-shadow: 0px 0px 57px -4px rgba(34, 60, 80, 0.5);
      box-shadow: 0px 0px 57px -4px rgba(34, 60, 80, 0.5);
      margin-top: -14px;
      position: relative;
      z-index: 1;
    }

    &::-moz-range-thumb {
      background: #fff;
      width: 32px;
      height: 32px;
      opacity: 0.9;
      background: url('@/assets/img/global/range-icon.png');
      border-radius: 50%;
      -webkit-box-shadow: 0px 0px 57px -4px rgba(34, 60, 80, 0.5);
      -moz-box-shadow: 0px 0px 57px -4px rgba(34, 60, 80, 0.5);
      box-shadow: 0px 0px 57px -4px rgba(34, 60, 80, 0.5);
      margin-top: -14px;
      position: relative;
      z-index: 1;
    }
  }

  &__values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    & p {
      color: $gray-color;
    }
  }
}
.filled {
  display: block;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  z-index: 0;
  pointer-events: none;
}
</style>
