<template>
  <div class="cookie-consent">
    <h2 class="cookie-consent__title">
      <img
        src="@/assets/img/notifications/cookie.png"
        alt="cookie-icon"
        class="cookie-consent__icon"
      />
      {{ $t('cookie_consent.title') }}
    </h2>

    <i18n-t
      keypath="cookie_consent.descr"
      tag="p"
      class="cookie-consent__descr"
    >
      <template #privacy>
        <router-link
          :to="{ name: 'privacy' }"
          target="_blank"
        >
          {{ $t('cookie_consent.privacy') }}
        </router-link>
      </template>
    </i18n-t>

    <div class="cookie-consent-btns">
      <button
        class="cookie-consent-btns__accept"
        @click="setCookieConsent('accepted')"
      >
        {{ $t('cookie_consent.btns.accept') }}
      </button>
      <button
        class="cookie-consent-btns__reject"
        @click="setCookieConsent('rejected')"
      >
        {{ $t('cookie_consent.btns.reject') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { useModalStore } from '@/stores/ModalStore';
import { useCookie } from '@/composables/useCookie';
import { useDataLayer } from '@/composables/useDataLayer';

const modals = useModalStore();
const { setCookie } = useCookie();
const { initGoogleAnalytics } = useDataLayer();

const setCookieConsent = (cookieStatus) => {
  modals.closePopup();
  setCookie('cookie_consent', cookieStatus, 365);
  if (cookieStatus === 'accepted') initGoogleAnalytics();
};
</script>

<style lang="scss">
.cookie-consent {
  position: fixed;
  bottom: 20px;
  right: 50%;
  transform: translateX(50%);
  z-index: 1000;
  width: 100%;
  max-width: 335px;
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #d9dbe9;
  background: #fff;
  box-shadow: 0px 5px 14px 0px rgba(8, 15, 52, 0.04);
  font-family:
    DM Sans,
    system-ui,
    -apple-system,
    Roboto,
    sans-serif;

  @media (min-width: 576px) {
    right: 30px;
    transform: none;
    max-width: 425px;
  }

  &__icon {
    display: inline-block;
    width: 16px;
  }

  &__title {
    margin-bottom: 8px;
    color: #170f49;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  &__descr {
    margin-bottom: 12px;
    color: #6f6c90;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    a {
      color: #4a3aff;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &-btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 13px;

    button {
      padding: 18px 5px;
      border-radius: 56px;
      font-size: 16px;
      line-height: 18px;
      border: none;
    }

    &__reject {
      border: 1px solid #eff0f6;
      background: #fff;
      box-shadow: 0px 4px 8px 0px rgba(74, 58, 255, 0.08);
      color: #170f49;
    }

    &__accept {
      background: #4a3aff;
      box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.18);
      color: #fff;
    }
  }
}
</style>
