const TRIGGERS_URLS = {
  triggers: 'api/v1/product/diary/triggers/:id'
};

export default (api) => ({
  getAll: () => api.get(TRIGGERS_URLS.triggers),
  getOne: (id) => api.get(TRIGGERS_URLS.triggers, { url_params: { id } }),
  create: (data) => api.post(TRIGGERS_URLS.triggers, data),
  delete: (id) => api.delete(TRIGGERS_URLS.triggers, { url_params: { id } })
});
