<template>
  <div class="tracker">
    <SVGComponent
      class="shape"
      :path="{ name: 'tracker-shape', dir: 'home' }"
    />
    <div class="tracker__wrapper">
      <h2 class="tracker__title title title-lg">{{ t('home.diary.moodTracker') }}</h2>
      <span class="tracker__subtitle">
        <p class="body body-lg">{{ t('home.diary.takeDailyNotes') }}</p>
      </span>

      <div class="tracker__body">
        <div class="plans">
          <p class="plans__title title title-sm">
            <span class="plans__title-day">{{ t('home.diary.today') }}</span>
            <span class="plans__title-date">
              {{ $d(new Date(), 'shortDay') }}
            </span>
          </p>

          <div
            class="plans__item"
            v-for="(item, itemIndex) in plans"
            :key="'plans' + itemIndex"
          >
            <div class="plans__time">
              <div :class="{ 'time-divider': itemIndex !== plans.length - 1 }">
                <SVGComponent
                  class="clock"
                  :path="{ name: 'clock', dir: 'home' }"
                />
              </div>
              {{ item.time }}
            </div>
            <div class="plans__card">
              <div class="plans__card-img">
                <SVGComponent
                  class="figure"
                  :class="[item.smile]"
                  :path="{ name: 'figure', dir: 'home' }"
                />

                <SVGComponent
                  class="smile"
                  :path="{ name: `smile-${item.smile}`, dir: 'home' }"
                />
              </div>
              <div class="plans__card-info">
                <div class="plans__card-top">
                  <h3 class="plans__card-title title title-xxs">
                    {{ t(item.title) }}
                  </h3>
                  <p class="plans__card-data">
                    <SVGComponent
                      class="clock"
                      :path="{ name: 'clock', dir: 'home' }"
                    />
                    <span>{{ item.time }}</span>
                    <span class="mark"> | </span>
                    <span>{{ t('home.diary.today') }}</span>
                  </p>
                </div>
                <p class="plans__card-text body body-sm">{{ t(item.text) }}</p>
              </div>
            </div>
          </div>
        </div>

        <Calendar />
      </div>
    </div>
  </div>
</template>

<script setup>
import Calendar from '@/components/shared/Calendar.vue';
import { useI18n } from 'vue-i18n';
const plans = [
  {
    title: 'home.diary.plans.first.title',
    text: 'home.diary.plans.first.text',
    time: '21:20',
    day: 'home.diary.plans.first.day',
    smile: 'orange'
  },
  {
    title: 'home.diary.plans.second.title',
    text: 'home.diary.plans.second.text',
    time: '16:30',
    day: 'home.diary.plans.second.day',
    smile: 'green'
  },
  {
    title: 'home.diary.plans.third.title',
    text: 'home.diary.plans.third.text',
    time: '15:30',
    day: 'home.diary.plans.third.day',
    smile: 'green'
  }
];

const { t } = useI18n();
</script>

<style lang="scss" scoped>
.tracker {
  flex: 1;
  padding: 40px 0 0 40px;
  width: 100%;

  height: 428px;
  border-radius: 25px;
  background: #9a80f6;
  overflow: hidden;
  position: relative;

  &__body {
    @media (max-width: 480px) {
      position: relative;
    }
  }

  @media (max-width: 1250px) {
    padding: 20px;
    max-height: 326px;
    max-width: 335px;
  }

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }

  &__wrapper {
    position: relative;
    z-index: 99;
  }

  &__title {
    margin-bottom: 4px;
    color: #f9faf5;

    @media (max-width: 1250px) {
      font-size: 24px;
    }
  }

  &__subtitle {
    display: block;
    margin-bottom: 16px;

    @media (max-width: 1250px) {
      margin-bottom: 25px;
      & p {
        font-size: 13px;
      }
    }

    & p {
      color: #fff;
    }
  }
}

.plans {
  padding: 24px 40px 50px 40px;
  width: 440px;

  border-radius: 21px;
  background: #bdaaff;

  @media (max-width: 1250px) {
    padding: 25px 11px;
    width: 205px;
    border-radius: 10px;
  }

  &__title {
    margin-bottom: 15px;
    color: #442b9d;

    @media (max-width: 1250px) {
      line-height: 1;
      margin-bottom: 12px;
    }

    &-day {
      color: $violet-100;

      @media (max-width: 1250px) {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.33;
      }
    }

    &-date {
      margin-left: 23px;
      color: #f9faf5;

      @media (max-width: 1250px) {
        margin-left: 7px;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.33;
      }
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    position: relative;
    z-index: 20;
  }

  &__time {
    display: flex;
    align-items: center;
    margin-right: 11px;
    height: 100%;
    transform: translateY(-5px);
    position: relative;

    color: #442b9d;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 1250px) {
      font-size: 6px;
      line-height: 1;
      margin-right: 5px;
      transform: translateY(0);
    }
  }

  &__card {
    display: flex;
    align-items: center;
    padding: 16px 14px;
    width: 100%;
    height: 100%;

    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 14px 0px #aba2cb;
    overflow: hidden;

    position: relative;

    @media (max-width: 1250px) {
      padding: 8px 6px;
      min-width: 144px;

      border-radius: 10px;
    }

    &-title {
      font-size: 14px;
      line-height: normal;
      margin: 0;

      @media (max-width: 899px) {
        font-size: 8px;
      }
    }

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 200px;
      margin-left: 20px;

      @media (max-width: 1250px) {
        margin-left: 15px;
      }
    }

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      @media (max-width: 1250px) {
        margin-bottom: 0px;
      }

      &__title {
        color: #1f1f1f;
      }
    }

    &-data {
      display: flex;
      align-items: center;
      color: #818181;
      font-size: 10px;
      line-height: 1;

      @media (max-width: 1250px) {
        color: #818181;
        font-size: 4px;
        font-style: normal;
        font-weight: 400;
      }

      .clock {
        fill: #b0b6c3;
        margin-right: 2px;
        width: 12px;
        height: 12px;

        @media (max-width: 1250px) {
          width: 6px;
          height: 6px;
        }
      }
      .mark {
        color: #d9d9d9;
        margin: 0 2px;
      }
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.time-divider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 4px;

  @media (max-width: 1250px) {
    height: 10px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 110%;
    transform: translateX(-50%);
    width: 2px;
    height: 58px;
    background: #442b9d;

    @media (max-width: 1250px) {
      width: 1px;
      height: 40px;
    }
  }
}

.clock {
  fill: #442b9d;
  width: 19px;
  height: 19px;

  @media (max-width: 1250px) {
    width: 10px;
    height: 10px;
  }
}

.figure {
  position: absolute;
  top: 0;
  left: 0;

  width: 70px;

  @media (max-width: 1250px) {
    width: 37px;
    height: 46px;
  }
}

.smile {
  width: 42px;
  height: 42px;
  object-fit: cover;
  z-index: 9;

  @media (max-width: 1250px) {
    width: 18px;
    height: 18px;
  }
}

.orange {
  fill: #fff6dd;
}

.green {
  fill: #e4ffd3;
}

.shape {
  width: 700px;
  height: 724px;
  fill: #7e63dd;
  position: absolute;
  top: -205px;
  left: 0px;
}
</style>
