import { useModalStore } from '@/stores/ModalStore.js';

import { createRouter, createWebHistory } from 'vue-router';

import Home from '@/views/Home.vue';
import Profile from '@/views/Profile.vue';
import Auth from '@/views/Auth.vue';
import { checkToken, isAuth, isSubscribed, setAuthLayout, isDemo } from '@/router/nav-guards';

const router = createRouter({
  history: createWebHistory(),

  routes: [
    {
      name: 'home',
      path: '/',
      meta: { title: 'home' },
      component: Home,
      beforeEnter: isAuth
    },
    {
      name: 'auth',
      path: '/auth',
      meta: { layout: 'AuthLayout', authorization: true },
      component: Auth,
      beforeEnter: isAuth,
      children: [
        {
          name: 'sign-in',
          path: 'sign-in',
          meta: { guests: true, authorization: true },
          component: () => import('@/components/pages/TheAuthorization/SignIn.vue')
        },
        {
          name: 'sign-up',
          path: 'sign-up',
          meta: { guests: true, authorization: true },
          component: () => import('@/components/pages/TheAuthorization/SignUp.vue')
        },
        {
          name: 'forgot-password',
          path: 'forgot-password',
          meta: { guests: true, authorization: true },
          component: () => import('@/components/pages/TheAuthorization/ForgotPassword.vue')
        },
        {
          name: 'change-password',
          path: 'change-password',
          meta: { guests: true, authorization: true },
          component: () => import('@/components/pages/TheAuthorization/ChangePassword.vue'),
          beforeEnter: checkToken
        }
      ]
    },
    {
      name: 'account',
      path: '/account',
      meta: { prevPage: 'home', title: 'profile' },
      component: Profile,
      beforeEnter: isAuth
    },
    {
      path: '/depression-test',
      beforeEnter: isSubscribed,
      children: [
        {
          name: 'depression-test',
          path: '',
          meta: { subscription: true, prevPage: 'home', title: 'depression_test' },
          component: () => import('../views/DepressionTests/index.vue')
        },
        {
          path: 'articles',
          meta: {
            subscription: true,
            prevPage: 'depression-test',
            title: 'articles',
            name: 'articles'
          },
          beforeEnter: isSubscribed,
          children: [
            {
              name: 'articles',
              path: '',
              component: () => import('../views/Articles/index.vue')
            },
            {
              name: 'article',
              path: ':id/view',
              meta: { prevPage: 'articles' },
              component: () => import('../views/Articles/Article.vue')
            }
          ]
        },
        {
          path: 'all-tests',
          meta: {
            subscription: true,
            prevPage: 'depression-test',
            title: 'all_tests',
            name: 'tests'
          },
          beforeEnter: isAuth,
          children: [
            {
              name: 'tests',
              path: '',
              component: () => import('../views/DepressionTests/Tests.vue')
            },
            {
              path: ':id',
              children: [
                {
                  name: 'tests-passing',
                  path: '',
                  meta: { layout: 'TestPassingLayout', subscription: true },
                  component: () => import('../views/DepressionTests/TestPassing.vue')
                },
                {
                  name: 'tests-result',
                  path: 'your-result',
                  meta: { subscription: true, prevPage: 'test', title: 'result' },
                  component: () => import('../views/DepressionTests/TestResult.vue')
                },
                {
                  name: 'test',
                  path: 'details',
                  meta: { subscription: true },
                  component: () => import('../views/DepressionTests/Test.vue')
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'mental-health',
      path: '/mental-health',
      meta: { subscription: true },
      beforeEnter: isSubscribed
    },
    {
      path: '/diary',
      beforeEnter: isSubscribed,
      children: [
        {
          name: 'diary',
          path: '',
          meta: { subscription: true, title: 'diary' },
          component: () => import('../views/Diary.vue')
        },
        {
          name: 'stats',
          path: 'stats',
          meta: { subscription: true, title: 'stats' },
          component: () => import('../views/Statistic.vue')
        }
      ]
    },
    {
      name: 'faq',
      path: '/faq',
      meta: {
        guests: true,
        layout: localStorage.getItem('token') ? null : 'AuthLayout',
        title: 'faq',
        prevPage: 'home'
      },
      component: () => import('../views/Faq.vue'),
      beforeEnter: setAuthLayout
    },
    {
      name: 'support',
      path: '/support',
      meta: {
        guests: true,
        prevPage: 'home',
        layout: localStorage.getItem('token') ? null : 'AuthLayout',
        title: 'support'
      },
      component: () => import('../views/Support.vue'),
      beforeEnter: setAuthLayout
    },
    {
      name: 'subscription-expired',
      path: '/subscription-expired',
      meta: { prevPage: 'home' },
      component: () => import('../views/Subscription.vue'),
      beforeEnter: isSubscribed
    },
    {
      name: 'terms',
      path: '/terms',
      meta: { guests: true, prevPage: 'home', title: 'Terms & Conditions', layout: 'LegalLayout' },
      component: () => import('../views/Terms.vue')
    },
    {
      name: 'privacy',
      path: '/privacy',
      meta: { guests: true, prevPage: 'home', title: 'Privacy Policy', layout: 'LegalLayout' },
      component: () => import('../views/Privacy.vue')
    },
    {
      name: 'demo',
      path: '/demoaccess',
      meta: { guests: true },
      beforeEnter: isDemo
    }
  ],
  scrollBehavior() {
    return { top: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const modals = useModalStore();

  window.scrollTo(0, 0);
  if (modals.mobileMenuState.component) {
    modals.toggleMobileMenu();
  }

  if (modals.popupState.closeOnRouteChange && modals.popupState.component) {
    modals.closePopup();
  }
  next();
});

export default router;
