export const TestsBorders = {
  test_1: {
    low: 10,
    middle: 19,
    high: 30
  },
  test_3: {
    low: 8,
    middle: 17,
    high: 24
  },
  test_4: {
    low: 16,
    middle: 31,
    high: 46
  },
  test_5: {
    low: 10,
    middle: 19,
    high: 30
  },
  test_6: {
    low: 5,
    middle: 10,
    high: 15
  }
};
