export function useDataLayer() {
  const initGoogleAnalytics = () => {
    if (import.meta.env.VITE_APP_ENV !== 'prod') return;

    const gtmId = 'GTM-N2TF2PMP';

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtmId);

    const noscript = document.createElement('noscript');
    const iframe = Object.assign(document.createElement('iframe'), {
      src: 'https://www.googletagmanager.com/ns.html?id=' + gtmId,
      height: '0',
      width: '0',
      style: {
        display: 'none',
        visibility: 'hidden'
      }
    });

    document.body.appendChild(noscript);
    noscript.appendChild(iframe);
  };

  const setEventView = (name) => {
    if (window.dataLayer) {
      window.dataLayer.push({ event_view: name });
    }
  };

  const setEvent = (name) => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: `event_${name}` });
    }
  };

  return {
    initGoogleAnalytics,
    setEventView,
    setEvent
  };
}
