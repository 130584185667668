<template>
  <li class="product">
    <img
      class="product__image"
      :src="imageUrl"
      :alt="item.id"
    />
    <div class="product__info">
      <div class="product__title">
        <p>{{ t(item.title) }}</p>
        <SVGComponent
          v-if="locked"
          :path="{ name: 'lock', dir: 'global', fill: '#222' }"
        />
      </div>
      <p class="product__text">{{ t(item.text) }}</p>
    </div>
    <div class="product__info-bottom">
      <div class="product__result-wrapper">
        <p
          v-if="!locked"
          class="product__result"
        >
          {{ t('home.yourProducts.yourResult') }}:
          <span
            :class="{ level: item.progress }"
            :style="{ color: item.progress && testsStore.generalProgressOptions.text }"
            >{{ item.progress ? testsStore.generalProgressOptions.title : '?' }}</span
          >
        </p>
      </div>

      <ProgressBar
        :progress="!locked ? testsStore.generalResults : 0"
        :progress-values="testsStore.generalProgressOptions"
        :lock="locked"
        :disabled="locked"
        class="product__progress"
      />

      <BaseButton
        :label="!locked ? t('home.yourProducts.learnMore') : t('home.yourProducts.unLock')"
        :variant="locked ? 'text' : 'default'"
        :disabled="locked ? locked : null"
        :to="{ name: 'depression-test' }"
        :class="[{ locked }, 'product__button button button-lg']"
        @click="setEvent('learn_depression')"
      />
    </div>
  </li>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTestsStore } from '@/stores/TestsStore.js';
import ProgressBar from '@/components/shared/ProgressBar.vue';
import { useDataLayer } from '@/composables/useDataLayer.js';
const { setEvent } = useDataLayer();
const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  locked: {
    type: Boolean,
    default: false
  }
});

const { t } = useI18n();
const testsStore = useTestsStore();

const imageUrl = computed(
  () => new URL(`../../../assets/img/png-products/${props.item.img}`, import.meta.url).href
);
</script>

<style lang="scss">
.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  width: 100%;

  max-width: 370px;
  min-height: 490px;

  padding: 20px 32px;

  border-radius: 20px;
  background: #f9faf5;

  @media (max-width: 480px) {
    justify-content: center;
    padding: 12px 20px;
  }

  @media (max-width: 1275px) {
    max-width: unset;
  }

  &__image {
    max-width: 310px;
    max-height: 195px;
    margin-bottom: 10px;
    width: 100%;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;

    & p {
      font-family: 'Nunito', sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media (max-width: 480px) {
        font-size: 18px;
      }
    }

    & svg {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }

  &__text {
    font-family: 'Nunito', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;

    @media (max-width: 480px) {
      margin-bottom: 0;
    }
  }

  &__result {
    @media (max-width: 480px) {
      font-size: 13px;
      margin-bottom: -16px;
    }

    .level {
      font-weight: 700;
    }

    &-wrapper {
      min-height: 26px;
      margin-bottom: 16px;
      width: 100%;
    }
  }

  &__progress {
    margin-bottom: 26px !important;
  }

  &__info {
    flex: 1 1 auto;
    width: 100%;
    &-bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  &__button {
    padding: 13px 0 !important;
  }
}
.locked {
  color: #222 !important;
  display: block !important;
  width: 100% !important;
  text-align: center !important;
}
</style>
