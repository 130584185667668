import { defineStore } from 'pinia';
import { ref } from 'vue';

const baseState = { component: null, props: {} };
export const useWarningsStore = defineStore('warningStore', () => {
  const warningState = ref(baseState);
  const showWarning = (data) => {
    const { component, props } = data;
    warningState.value = { component, props: props || {} };
  };

  const hideWarning = () => {
    warningState.value = baseState;
  };

  return {
    warningState,
    showWarning,
    hideWarning
  };
});
