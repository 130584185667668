<template>
  <div class="home">
    <h1 class="home__title title title-xl">
      <span class="home__first-title">
        {{ $t('home.title') }}
        <SVGComponent :path="{ name: 'hi-emoji', dir: 'home' }" />
      </span>
      <span
        class="home__second-title"
        v-html="$t('home.secondTitle')"
      />
    </h1>

    <p class="home__subtitle body body-xl">
      {{ $t('home.subTitle') }}
    </p>

    <div class="all-products">
      <Products />
      <SVGComponent
        class="shape shape__left"
        :path="{ name: 'shape', dir: 'home' }"
      />
      <SVGComponent
        class="shape shape__right"
        :path="{ name: 'shape', dir: 'home' }"
      />
      <SVGComponent
        class="shape"
        :path="{ name: 'shape', dir: 'home' }"
      />
    </div>

    <div class="diary">
      <h2 class="diary__title title title-lg">
        {{ $t('home.diary.title') }}
      </h2>
      <p class="diary__subtitle body body-xl">
        {{ $t('home.diary.description') }}
      </p>
      <div class="diary__wrapper">
        <Tracker />

        <NoteBook />
      </div>
    </div>

    <div class="articles">
      <div class="articles__title">
        <h2 class="title title-lg">{{ $t('home.articles.title') }}</h2>
        <base-button
          variant="lineText"
          :label="$t('home.articles.seeAll')"
          :icon="{ name: 'arrow', dir: 'global', fill: '#8145E1' }"
          class="articles__button button button-lg"
          :to="{ name: 'articles' }"
          @click="setEvent('all_articles')"
        />
        <BaseButton
          class="articles__button-mob button button-lg"
          variant="icon"
          :icon="{ name: 'angle-down', dir: 'global', fill: '#222' }"
          :to="{ name: 'articles' }"
        />
      </div>
      <p class="articles__subtitle body body-xl">
        {{ $t('home.articles.description') }}
      </p>

      <div class="articles__list">
        <ArticleCard
          v-for="article in homePageArticles"
          :key="article.id"
          :article="article"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Products from '@/components/pages/Home/Products.vue';
import ArticleCard from '@/components/shared/ArticleCard.vue';
import NoteBook from '@/components/pages/Home/NoteBook.vue';
import Tracker from '@/components/pages/Home/Tracker.vue';

import { computed, onMounted } from 'vue';

import { useMainStore } from '@/stores/MainStore.js';
import { useSwitchLanguage } from '@/composables/useSwitchLanguage.js';
import { useDataLayer } from '@/composables/useDataLayer.js';

const main = useMainStore();
const { usersLocale } = useSwitchLanguage();
const { setEventView, setEvent } = useDataLayer();

const homePageArticles = computed(() => main.articles.slice(0, 2));
const firstTitleMaxWidth = computed(() => (usersLocale.value === 'en' ? '230px' : '260px'));

onMounted(() => {
  setEventView('main_page');
});
</script>

<style lang="scss" scoped>
.all-products {
  width: 100%;
  padding: 60px 50px;
  border-radius: 30px;
  background: $secondary-bg;
  position: relative;
  overflow: hidden;
  margin-top: 45px;

  @media (max-width: 480px) {
    margin-top: 24px;
    padding: 20px;
  }

  &__title {
    position: relative;
    z-index: 10;
    margin-bottom: 10px;
  }

  &__description {
    position: relative;
    z-index: 10;
    max-width: 77%;
    margin-bottom: 40px;

    @media (max-width: 480px) {
      max-width: 100%;
      margin-bottom: 12px;
      font-size: 13px;
      line-height: 1.7;
    }
  }
}

.home {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1260px;

  background: #f7f4f0;

  margin: 0 auto;

  &__title {
    margin-bottom: 10px;

    @media (max-width: 480px) {
      margin-bottom: 16px;
      margin-top: 0;
    }

    img {
      margin-left: 13px;
    }

    :deep & .marker {
      color: #82a600;
    }
  }

  &__first-title {
    position: relative;
    display: flex;
    align-items: center;
    gap: 13px;
    width: fit-content;

    & svg {
      position: absolute;
      bottom: 5px;
      right: -50px;

      @media (max-width: 480px) {
        left: 102%;
      }
    }

    @media (max-width: 480px) {
      display: block;
      margin-bottom: 8px;
      max-width: v-bind(firstTitleMaxWidth);
    }
  }

  &__second-title {
    display: block;

    @media (max-width: 480px) {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }
  }
}

.diary {
  margin: 80px 0;

  @media (max-width: 480px) {
    margin: 40px 0;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__subtitle {
    margin-bottom: 40px;

    @media (max-width: 480px) {
      margin-bottom: 24px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center !important;
    }

    @media (max-width: 1250px) {
      align-items: flex-start;
      justify-content: center;
    }
  }
}

.articles {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    display: flex;
    align-items: flex-end;

    @media (max-width: 480px) {
      align-items: center;
      justify-content: space-between;
    }
  }
  &__subtitle {
    margin: 10px 0 30px;

    @media (max-width: 480px) {
      font-size: 13px;
      line-height: 1.7;
      padding-right: 30px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 44px;

    @media (max-width: 899px) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 16px;
    padding: 0;
    color: $violet-90;
    border-radius: 100px;
    line-height: 1.65;

    &::before {
      display: none;
    }

    @media (max-width: 480px) {
      display: none;
    }

    &-mob {
      transform: rotate(-90deg);

      @media (min-width: 481px) {
        display: none;
      }
    }
  }
}

.shape {
  width: 400px;
  height: 635px;
  fill: #d7e2b3;
  transform: rotate(81deg);
  position: absolute;
  bottom: -270px;
  left: 33px;

  &__left {
    width: 250px;
    height: 400px;
    transform: rotate(180deg);
    bottom: auto;
    top: -215px;
    left: -100px;
  }
  &__right {
    width: 250px;
    height: 400px;
    transform: rotate(268deg);
    bottom: auto;
    left: auto;
    top: -145px;
    right: -45px;
  }
}
</style>
