<template>
  <div class="container">
    <HeadNotificationWrapper v-if="notifies.warningState.component && !isLegalPage" />
    <component :is="layout">
      <transition
        name="local-fade"
        appear
        mode="out-in"
      >
        <router-view />
      </transition>
    </component>
    <teleport to="body">
      <Transition
        name="local-fade"
        appear
        mode="out-in"
      >
        <BaseMobileModal v-if="modalStore.mobileMenuState.overlay" />
      </Transition>
    </teleport>
    <teleport to="body">
      <Transition name="fade">
        <BaseModal v-if="modalStore.modalState.component" />
      </Transition>
    </teleport>
    <teleport to="body">
      <Transition name="fade">
        <BasePopup v-if="modalStore.popupState.component" />
      </Transition>
    </teleport>
    <teleport to="body">
      <Transition name="toast">
        <BaseToast v-if="modalStore.toastState.component" />
      </Transition>
    </teleport>
  </div>
</template>

<script setup>
import BaseModal from '@/components/shared/Modals/BaseModal.vue';
import BasePopup from '@/components/shared/Popups/BasePopup.vue';
import { useMainStore } from '@/stores/MainStore.js';
import { useModalStore } from '@/stores/ModalStore.js';
import { useRouter } from 'vue-router';
import { computed, defineAsyncComponent, onBeforeMount, ref, watch, watchEffect } from 'vue';
import { useAuthStore } from '@/stores/AuthStore.js';
import BaseMobileModal from '@/components/shared/Modals/BaseMobileModal.vue';
import HeadNotificationWrapper from '@/components/shared/Notifications/HeadNotificationWrapper.vue';
import { useWarningsStore } from '@/stores/WarningsStore.js';
import RenewSubscription from '@/components/shared/Notifications/_components/RenewSubscription.vue';
import { useSwitchLanguage } from '@/composables/useSwitchLanguage.js';
import BaseToast from '@/components/shared/Toasts/BaseToast.vue';

const main = useMainStore();
const modalStore = useModalStore();
const auth = useAuthStore();
const notifies = useWarningsStore();

const router = useRouter();
const { usersLocale, switchLanguage } = useSwitchLanguage();

const layout = ref(defineAsyncComponent(() => import('@/layouts/DefaultLayout.vue')));
const logged = computed(() => auth.isLoggedIn && router.currentRoute.value.name !== 'auth');
const isLegalPage = computed(
  () => router.currentRoute.value.name === 'terms' || router.currentRoute.value.name === 'privacy'
);

onBeforeMount(() => {
  if (logged.value) auth.getUserProfile();
  switchLanguage(usersLocale.value);
  main.loadCookieConsent();
});

watchEffect(() => {
  if (!auth.user.paid && auth.isLoggedIn) {
    notifies.showWarning({
      component: RenewSubscription
    });
  } else {
    notifies.hideWarning();
  }
});

watch(
  () => router.currentRoute.value.meta,
  (meta, oldMeta) => {
    if (meta.layout !== oldMeta.layout) {
      const layoutName = meta.layout ? meta.layout : 'DefaultLayout';
      layout.value = defineAsyncComponent(() => import(`@/layouts/${layoutName}.vue`));
    }
  }
);
</script>

<style lang="scss">
@import './assets/styles/mixins.scss';

@include local-fade(0.2s, local-fade);

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.2s ease all;
}

.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateX(-100%);

  @media (max-width: 899px) {
    transform: translateY(-100%);
  }
}

.toast-enter-active,
.toast-leave-active {
  transition: 0.8s ease all;
}

html.noaff span.noaff {
  display: none;
}

html {
  font-family: 'Nunito', sans-serif;
  height: 100%;
}

body {
  background: #f7f4f0;
}

#app {
  height: 100%;
}
</style>
