<template>
  <div class="profile-page">
    <ProfilePanelDesktop
      class="profile-page__desktop"
      :time-zones="main.timeZones"
      :subscription-message="expiringMessage"
      :selected-time-zone="auth.user.timezone"
      :langs="main.languages"
      :selected-lang="auth.user.language"
      :user="auth.user"
      @change-input-value="changeInputValue"
      @logout="onLogout"
      @save="onSaveChanges"
    />
    <ProfilePanelMobile
      class="profile-page__mob"
      :time-zones="main.timeZones"
      :subscription-message="expiringMessage"
      :selected-time-zone="auth.user.timezone"
      :langs="main.languages"
      :selected-lang="auth.user.language"
      :user="auth.user"
      @change-input-value="changeInputValue"
      @logout="onLogout"
      @save="onSaveChanges"
    />
  </div>
</template>

<script setup>
import ProfilePanelDesktop from '@/components/pages/ProfilePanel/ProfilePanelDesktop.vue';
import ProfilePanelMobile from '@/components/pages/ProfilePanel/ProfilePanelMobile.vue';
import useVuelidate from '@vuelidate/core';
import { useAuthStore } from '@/stores/AuthStore.js';
import { useMainStore } from '@/stores/MainStore.js';
import { required, sameAs, not } from '@vuelidate/validators';
import { computed, reactive, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { validationRules } from '@/helpers/validation-rules.js';
import dayjs from 'dayjs';

const auth = useAuthStore();
const main = useMainStore();

const { t } = useI18n();

const changeInputValue = async (item) => {
  auth.inputs.profile[item.key].value = item.value;
};

const onLogout = () => {
  auth.logout();
};

const expiringMessage = computed(() => {
  const count = dayjs(auth.user.payment_expired_at).diff(dayjs(), 'day');
  let message;
  if (!count && count !== 0) {
    message = t('profile.subscription_expires.has_expired');
  } else if (count === 0) {
    message = t('profile.subscription_expires.expires_today');
  } else if (count === 1) {
    message = t('profile.subscription_expires.expires_tomorrow');
  } else if (count > 1) {
    message = t('profile.subscription_expires.expires_in', { n: count });
  }

  return message;
});

const inputsAreValid = computed(
  () =>
    Object.values(auth.inputs.profile).every((input) => input.valid) &&
    Object.values(auth.inputs.profile_passwords).every((input) => input.valid)
);

const onSaveChanges = async () => {
  const profile_keys = Object.keys(auth.inputs.profile);
  const passwords_keys = Object.keys(auth.inputs.profile_passwords);
  for (const key of passwords_keys) {
    if (v$_pass[key] && isPasswordFilledIn.value) {
      // eslint-disable-next-line no-unused-expressions
      (validations[key].required = isPasswordFilledIn.value ? required : false),
        (auth.inputs.profile_passwords[key].valid = await v$_pass[key].value.$validate());
      auth.inputs.profile_passwords[key].error = v$_pass[key].$error
        ? v$_pass[key].$errors[0].$validator
        : '';
    }
  }
  for (const key of profile_keys) {
    if (v$_profile[key]) {
      auth.inputs.profile[key].valid = await v$_profile[key].value.$validate();
      auth.inputs.profile[key].error = v$_profile[key].$error
        ? v$_profile[key].$errors[0].$validator
        : '';
    }
  }
  const payloadMap = new Map();
  for (const [key, value] of Object.entries(auth.inputs.profile)) {
    if (value.value) {
      payloadMap.set(key, value.value);
    }
  }
  for (const [key, value] of Object.entries(auth.inputs.profile_passwords)) {
    if (value.value) {
      payloadMap.set(key, value.value);
    }
  }

  const payload = Object.fromEntries(payloadMap);

  if (inputsAreValid.value) await auth.updateProfileInfo(payload);
};

const isPasswordFilledIn = computed(() =>
  Object.values(auth.inputs.profile_passwords).some((input) => input.value)
);

const validations = reactive({
  name: {
    value: {
      ...validationRules.name.value
    }
  },
  current_password: {
    value: {
      ...validationRules.password.value
    }
  },
  new_password: {
    value: {
      ...validationRules.password.value,
      notSameAs: not(sameAs(computed(() => auth.inputs.profile_passwords.current_password.value)))
    }
  },
  new_password_confirmation: {
    value: {
      ...validationRules.password.value,
      sameAs: sameAs(computed(() => auth.inputs.profile_passwords.new_password.value))
    }
  }
});
const v$_profile = useVuelidate(validations, auth.inputs.profile).value;
const v$_pass = useVuelidate(validations, auth.inputs.profile_passwords).value;

onBeforeUnmount(auth.resetProfileValues);
</script>

<style lang="scss" scoped>
.profile-page {
  max-width: 1260px;
  margin: 0 auto;
  padding: 60px 80px;
  border-radius: 10px;
  background: #fff;
  box-shadow:
    0px 10px 20px 0px rgba(194, 194, 194, 0.16),
    0px 0px 30px 0px rgba(170, 170, 170, 0.16);

  @media (max-width: 899px) {
    background: none;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }

  &__desktop {
    @media (max-width: 899px) {
      display: none;
    }
  }

  &__mob {
    max-width: 500px;
    margin: 0 auto;
    @media (min-width: 900px) {
      display: none;
    }
  }
}
</style>
