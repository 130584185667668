<template>
  <component
    v-if="imageExists"
    :is="imgComponent"
    :data="data"
    :fill="path.fill || 'none'"
    :empty="path.empty"
    :circle="circle"
    :minus="minus"
    :checked="checked"
  />
</template>

<script setup>
import { computed, defineAsyncComponent, shallowRef, watch } from 'vue';
import TheLoader from '@/components/ui/TheLoader.vue';

const props = defineProps({
  path: {
    type: Object,
    required: true
  },
  data: Object,
  circle: {
    type: Boolean,
    default: false
  },
  minus: {
    type: Boolean,
    default: false
  },
  checked: {
    type: Boolean,
    default: false
  }
});

const imgComponent = shallowRef(
  defineAsyncComponent(() => import(`../../assets/img/${props.path.dir}/${props.path.name}.vue`))
);
const imageExists = computed(() => props.path.name && props.path.dir);
const forcedComponentRerender = () => {
  imgComponent.value = defineAsyncComponent({
    loader: () => import(`../../assets/img/${props.path.dir}/${props.path.name}.vue`),
    loadingComponent: TheLoader
  });
};

watch(
  () => props.path,
  (newValue, oldValue) => {
    if (newValue.name === oldValue.name) {
      return;
    }
    forcedComponentRerender();
  }
);
</script>
