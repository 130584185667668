export default {
  en: {
    shortDay: {
      day: 'numeric',
      month: 'short'
    },
    shortMonth: {
      month: 'short',
      year: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  fr: {
    shortDay: {
      day: 'numeric',
      month: 'short'
    },
    shortMonth: {
      month: 'short',
      year: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    }
  }
};
