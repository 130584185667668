<template>
  <div class="head-notification">
    <component
      :is="warnings.warningState.component"
      v-bind="warnings.warningState.props"
    />
  </div>
</template>

<script setup>
import { useWarningsStore } from '@/stores/WarningsStore.js';

const warnings = useWarningsStore();
</script>

<style lang="scss">
.head-notification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 199;
}
</style>
