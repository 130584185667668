<template>
  <div class="notebook">
    <SVGComponent
      class="spring"
      :path="{ name: 'notebook-spring', dir: 'home' }"
    />
    <div class="notebook__wrapper">
      <SVGComponent
        class="circles"
        :path="{ name: 'notebook-circles', dir: 'home' }"
      />
      <SVGComponent
        class="star"
        :path="{ name: 'notebook-star', dir: 'home' }"
      />
    </div>

    <div class="notebook__content">
      <h2 class="notebook__title title title-lg">
        {{ t('home.diary.emotionalDiary') }}
      </h2>

      <BaseButton
        :label="t('home.diary.learnMore')"
        :to="{ name: 'diary' }"
        class="notebook__button button button-lg"
        @click="setEvent('learn_diary')"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useDataLayer } from '@/composables/useDataLayer.js';

const { t } = useI18n();
const { setEvent } = useDataLayer();
</script>

<style lang="scss" scoped>
.notebook {
  flex: 1;
  margin-left: 48px;
  max-width: 408px;
  width: 100%;
  height: 100%;
  height: 428px;

  border-radius: 25px;
  background: #222;
  position: relative;

  @media (max-width: 768px) {
    max-width: 335px;
    margin-left: 0;
  }

  @media (max-width: 1250px) {
    height: unset;
  }

  @media (max-width: 480px) {
    margin-left: 0;
    height: 338px;
    flex: unset;
  }

  &__title {
    color: $white-color;

    @media (max-width: 1250px) {
      font-size: 24px;
      line-height: 1.25;
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    border-radius: 25px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 48px 40px 40px 45px;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 20;

    @media (max-width: 480px) {
      padding: 49px 32px 32px 36px;
    }

    @media (max-width: 1250px) {
      height: 326px;
    }
  }

  &__button {
    margin-left: auto;
    width: 165px;
  }
}

.spring {
  height: 85%;
  position: absolute;
  top: 8%;
  left: -2%;
  z-index: 20;
}

.circles {
  position: absolute;
  bottom: -100px;
  left: -100px;
  width: 251px;
  height: 256px;
}

.star {
  position: absolute;
  right: -30px;
  bottom: 60px;
  width: 127px;
  height: 130px;
}
</style>
