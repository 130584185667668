const DIARY_URLS = {
  quotes: 'api/v1/product/diary/quotes-of-the-day',
  records: 'api/v1/product/diary/records/:id',
  calendar_moods: 'api/v1/product/diary/calendar/moods',
  stats_moods: '/api/v1/product/diary/statistics/moods',
  stats_triggers: 'api/v1/product/diary/statistics/moods/:id/triggers'
};

export default (api) => ({
  getQuotes: () => api.get(DIARY_URLS.quotes),
  addRecord: (data) => api.post(DIARY_URLS.records, data),
  allRecords: ({ date }) => api.get(DIARY_URLS.records, { query: { date } }),
  calendarMoods: ({ from }) => api.get(DIARY_URLS.calendar_moods, { query: { from } }),
  statsMoods: ({ from }) => api.get(DIARY_URLS.stats_moods, { query: { from } }),
  statsTriggers: ({ id, from }) =>
    api.get(DIARY_URLS.stats_triggers, { url_params: { id }, query: { from } }),
  removeRecord: (id) => api.delete(DIARY_URLS.records, { url_params: { id } }),
  editRecord: ({ id, data }) => api.patch(DIARY_URLS.records, data, { url_params: { id } })
});
