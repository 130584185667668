const BASE_URLS = {
  mood: '/api/v1/product/diary/moods',
  faq: '/api/v1/faq',
  support: '/api/v1/support',
  meta: '/api/v1/user/meta/:click_id',
  payments: '/api/v1/user/payment'
};

export default (api) => ({
  getMoods: () => api.get(BASE_URLS.mood),
  getFaqContent: () => api.get(BASE_URLS.faq),
  sendSupportMessage: (data) => api.post(BASE_URLS.support, data),
  updateMeta: (data) => api.post(BASE_URLS.meta, data),
  getMeta: (id) => api.get(BASE_URLS.meta, { url_params: { click_id: id } }),
  pay: (data) => api.post(BASE_URLS.payments, data)
});
