<template>
  <div class="legal">
    <ul class="links">
      <li v-for="({ label, id, to }, idx) of footerLinks">
        <base-button
          :divider="idx !== footerLinks.length - 1"
          :key="id"
          variant="text"
          :target="id === 'privacy' || id === 'terms' ? '_blank' : null"
          :to="to"
          :label="label"
          class="links__link"
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const footerLinks = [
  {
    id: 'privacy',
    label: t('footer.privacy'),
    to: { name: 'privacy' }
  },
  {
    id: 'terms',
    label: t('footer.terms'),
    to: { name: 'terms' }
  }
];
</script>

<style lang="scss" scoped>
.links {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 10px;

  &__link {
    color: $main-100 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 26px !important;

    @media (max-width: 480px) {
      font-size: 10px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 1.6 !important;
    }
  }
}

.ai-info {
  text-align: center;
  font-size: 13px;
  line-height: 1.38;

  &__link {
    display: inline;
    padding: 0;
    text-decoration: underline;
    color: #85a11d;
    font-size: inherit;
    line-height: inherit;
  }
}
</style>
