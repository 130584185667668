<template>
  <div
    class="progress"
    :class="{ disabled: disabled }"
    :style="{ background: progressValues?.container }"
  >
    <div
      class="progress__fill"
      :style="{
        width: (progress >= 90 ? 90 : progress) + '%',
        background: progressValues?.progress
      }"
    >
      <div
        class="progress__circle"
        :style="{ background: progressValues?.container, borderColor: progressValues?.progress }"
      >
        <SVGComponent
          :class="{ smile: !lock }"
          :mood="progressValues?.smile"
          :path="smileOptions"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  progress: Number,
  lock: Boolean,
  disabled: Boolean,
  progressValues: {
    type: Object,
    required: true
  }
});

const smileOptions = computed(() => ({
  name: props.lock ? 'quest' : 'progress-smiles',
  dir: 'global',
  fill: props.lock ? '#A4A5A3' : props.progressValues?.container
}));
</script>

<style lang="scss" scoped>
.progress {
  margin-bottom: 32px;
  width: 100%;
  height: 9px;
  border-radius: 20px;
  position: relative;

  &__fill {
    position: relative;
    height: 9px;
    border-radius: 20px;
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 95%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    background: #e5f4ec;
    border: 1px solid #66c792;
    border-radius: 50%;

    :deep & svg {
      width: 65%;
      height: 65%;
    }
  }

  &.disabled {
    background: #e8eee7 !important;

    .progress__circle {
      background: #f1f5f0 !important;
      border: 1px solid #a4a5a3 !important;
    }
  }
}
</style>
