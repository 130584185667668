<template>
  <div class="footer">
    <div class="footer__wrapper">
      <div class="footer__top">
        <div class="footer__left">
          <Logo />
        </div>

        <div class="footer__center">
          <p>
            {{ t('footer.questions.youCan') }}
            <router-link
              class="highlighter"
              :to="{ name: 'faq' }"
              exact
              @click="setEvent('footer_faq')"
            >
              {{ t('footer.questions.faqSection') }}
            </router-link>
            {{ t('footer.questions.contact') }}
            <router-link
              :to="{ name: 'support' }"
              class="highlighter"
              @click="setEvent('footer_support')"
            >
              {{ t('footer.questions.supportTeam') }}
            </router-link>
          </p>
        </div>

        <div class="footer__right">
          <FooterLinks />
        </div>
      </div>
      <div class="footer__bottom">
        <span class="footer__address">TrafficLeader OÜReg. No.: 16350328</span>
        <span>
          Address: Harju maakond, Tallinn, Lasnamäe linnaosa, Pae tn 21-25, 11415, Estonia
        </span>
      </div>
      <div class="footer__back">
        <SVGComponent
          class="footer__back-img"
          :path="{ name: 'footer-back', dir: 'home' }"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Logo from '@/components/ui/Logo.vue';
import { useI18n } from 'vue-i18n';
import FooterLinks from '@/components/shared/FooterLinks.vue';
import { useDataLayer } from '@/composables/useDataLayer.js';
const { t } = useI18n();
const { setEvent } = useDataLayer();
</script>

<style lang="scss">
.footer {
  padding-top: 85px;
  width: 100%;

  position: relative;

  &__back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    overflow: hidden;

    &-img {
      position: absolute;
      top: 0;
      left: -5vw;
      width: 110vw;

      @media (max-width: 480px) {
        top: 50px;
        width: 625px;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 60px 20px;
    background-color: #eae6e2;
    font-size: 16px;

    @media (max-width: 480px) {
      padding: 20px 30px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1330px;
    margin-bottom: 75px;
    position: relative;
    z-index: 10;

    @media (max-width: 899px) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
    }
  }

  &__left {
    @media (max-width: 480px) {
      margin-bottom: 24px;
    }

    & .logo {
      max-width: 271px;
      @media (max-width: 480px) {
        max-width: 175px;
      }
    }
  }

  &__center {
    width: 100%;
    max-width: 460px;
    text-align: center;

    font-weight: 700;
    line-height: 18px;

    & p {
      & a {
        color: #85a11d !important;
        text-decoration: underline !important;
      }
    }

    @media (max-width: 899px) {
      margin: 0;
      & p {
        font-size: 13px;
        line-height: 1.38;
      }
    }
  }

  &__right {
    @media (max-width: 480px) {
      margin-top: 24px;
    }
  }

  &__bottom {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1330px;

    & span {
      color: #b19d9d;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.6;
    }

    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 250px;
      margin: 0 auto;

      & span {
        color: #b19d9d;
        text-align: center;
        font-family: Nunito;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.6;
      }
    }
  }

  &__address {
    margin-right: 25px;
    @media (max-width: 480px) {
      margin-right: 0;
    }
  }
}
</style>
