<template>
  <component
    :is="component"
    :disabled="disabled"
    class="base-select"
  />
</template>

<script setup>
import InputSelect from './InputSelect.vue';
import { computed } from 'vue';

const component = computed(
  () =>
    ({
      input: InputSelect
    })[type]
);

const { type } = defineProps({
  type: {
    type: String,
    default: 'input'
  },
  disabled: {
    type: Boolean,
    default: false
  }
});
</script>

<style lang="scss">
.base-select {
  &[disabled] {
    pointer-events: none;
    color: #b5b5b5 !important;
  }
}
</style>
