import { email, maxLength, minLength, numeric, required } from '@vuelidate/validators';

export const validationRules = {
  name: {
    value: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(32)
    }
  },
  password: {
    value: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(20)
    }
  },
  email: {
    value: {
      required,
      email,
      minLength: minLength(6),
      maxLength: maxLength(40)
    }
  },
  phone: {
    value: {
      numeric,
      minLength: minLength(8),
      maxLength: maxLength(13)
    }
  },
  message: {
    value: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(1500)
    }
  }
};
