import axios from 'axios';
import { useAuthStore } from '@/stores/AuthStore.js';
import { useSwitchLanguage } from '@/composables/useSwitchLanguage.js';
import { useModalStore } from '@/stores/ModalStore.js';
import router from '@/router/index.js';
const requireToken = ['profile', 'product', 'articles', 'payment'];

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Service-Id': import.meta.env.VITE_SERVICE_ID
};

const baseRequest = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    post: headers,
    get: headers,
    patch: headers,
    delete: headers
  }
});

baseRequest.interceptors.request.use(
  (config) => {
    const currentUrl = new URL(config.url, config.baseURL);
    const { usersLocale } = useSwitchLanguage();

    config.headers['X-Locale'] = usersLocale.value;

    if (config.url_params) {
      Object.entries(config.url_params || {}).forEach(([k, v]) => {
        currentUrl.pathname = currentUrl.pathname.replace(`:${k}`, encodeURIComponent(v));
      });
    } else {
      currentUrl.pathname = currentUrl.pathname.replace('/:id', '');
    }

    if (config.query) {
      Object.entries(config.query || {}).forEach(([k, v]) => {
        currentUrl.searchParams.set(k, v);
      });
    }

    if (requireToken.find((item) => config.url.includes(item))) {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    }

    return {
      ...config,
      url: currentUrl.pathname + currentUrl.search
    };
  },
  (error) => Promise.reject(error)
);

baseRequest.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    const auth = useAuthStore();
    const modals = useModalStore();

    const { response: globalError } = error;
    const errorsWithoutToast = [422, 402];

    if (!errorsWithoutToast.includes(globalError.status)) {
      modals.openToast({ type: 'error' });
    }

    if (globalError.status === 402) {
      router.push({ name: 'subscription-expired' });
    }

    if (globalError.status === 401) {
      auth.logout();
    }
    return Promise.reject(error);
  }
);

export { baseRequest };
