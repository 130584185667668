import i18n from '@/i18n';
import { computed, nextTick, ref } from 'vue';
import { useAuthStore } from '@/stores/AuthStore.js';
import dayjs from 'dayjs';
export const useSwitchLanguage = () => {
  const auth = useAuthStore();

  const defaultLocale = ref(import.meta.env.VITE_DEFAULT_LOCALE);

  const supportedLocales = ref(import.meta.env.VITE_SUPPORTED_LOCALES.split(','));

  const usersBrowserLocale = computed(() => {
    const locale =
      window.navigator.language || window.navigator.userLanguage || defaultLocale.value;
    return {
      locale,
      localeNoRegion: locale.split('-')[0]
    };
  });

  const usersLocale = computed(() => {
    const existLanguage = auth.user?.language || localStorage.getItem('lang');
    if (existLanguage) {
      return existLanguage;
    } else {
      if (supportedLocales.value.includes(usersBrowserLocale.value.locale)) {
        return usersBrowserLocale.value.locale;
      } else if (supportedLocales.value.includes(usersBrowserLocale.value.localeNoRegion)) {
        return usersBrowserLocale.value.localeNoRegion;
      }
      return defaultLocale.value;
    }
  });

  const loadLocaleMessages = async (locale) => {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await import(`@/i18n/locales/${locale}/index.json`);
      i18n.global.setLocaleMessage(locale, messages.default);
    }

    return nextTick();
  };

  const switchLanguage = async (newLocale) => {
    await loadLocaleMessages(newLocale);
    i18n.global.locale.value = newLocale;
    dayjs.locale(newLocale);
    document.querySelector('html').setAttribute('lang', newLocale);
    localStorage.setItem('lang', newLocale);
  };

  return { usersLocale, defaultLocale, supportedLocales, switchLanguage };
};
