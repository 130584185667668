<template>
  <component
    :is="toastComponent"
    v-bind="modals.toastState.props"
    class="toast-component"
  />
</template>

<script setup>
import { computed, defineAsyncComponent, onMounted, ref } from 'vue';
import { useModalStore } from '@/stores/ModalStore.js';
const props = defineProps({
  type: {
    type: String,
    default: 'error'
  }
});
const modals = useModalStore();
const name = computed(() => ({ error: 'ErrorToast' })[props.type]);
const toastComponent = ref(
  defineAsyncComponent(() => import(`../../../components/shared/Toasts/${name.value}.vue`))
);

onMounted(() => {
  setTimeout(() => {
    modals.closeToast();
  }, 5000);
});
</script>

<style lang="scss">
.toast-component {
  z-index: 9999;
}
</style>
