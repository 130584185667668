import { defineStore } from 'pinia';
import { ref, nextTick } from 'vue';
import ExitConfrim from '@/components/shared/Popups/ExitConfrim.vue';

const basicState = { component: null, props: {}, overlay: true };

export const useModalStore = defineStore('ModalStore', () => {
  const modalState = ref(basicState);
  const popupState = ref(basicState);
  const toastState = ref(basicState);

  const mobileMenuState = ref({
    component: false,
    overlay: false
  });

  let resolve;
  let reject; // eslint-disable-line no-unused-vars

  const setModalExitOptions = (payload) => {
    modalState.value.props = {
      confirm: true,
      exitText: {
        title: payload.title,
        text: payload.text
      },
      action: payload.action
    };
  };
  const toggleMobileMenu = () => {
    mobileMenuState.value.component = !mobileMenuState.value.component;
    if (!mobileMenuState.value.overlay) {
      mobileMenuState.value.overlay = true;
    } else {
      setTimeout(() => {
        mobileMenuState.value.overlay = false;
      }, 200);
    }
  };

  const openModal = (data) => {
    const { component, props } = data;

    const modalPromise = new Promise((ok, fail) => {
      resolve = ok;
      reject = fail;
    });

    modalState.value = { component, props: props || {} };

    if (document.body) {
      document.body.style.overflow = 'hidden';
    }

    return modalPromise;
  };
  const closeModal = async () => {
    resolve(true);

    if (modalState.value.props.confirm) {
      const response = await openPopup({
        component: ExitConfrim,
        props: {
          title: modalState.value.props.exitText.title,
          text: modalState.value.props.exitText.text,
          action: modalState.value.props.action
        }
      });
      if (!response) return;
    }

    modalState.value = basicState;

    if (document.body) {
      document.body.style.overflow = 'auto';
    }
  };

  const openPopup = (data) => {
    const { component, props = {}, overlay = true, closeOnRouteChange = true } = data;

    const modalPromise = new Promise((ok, fail) => {
      resolve = ok;
      reject = fail;
    });

    nextTick(() => {
      popupState.value = { component, props, overlay, closeOnRouteChange };
    });

    return modalPromise;
  };

  const closePopup = (cancel) => {
    resolve(cancel ? false : true);
    popupState.value = basicState;
  };

  const openToast = (props) => {
    toastState.value = { component: true, props: props || {} };
  };
  const closeToast = () => {
    toastState.value = basicState;
  };

  return {
    modalState,
    popupState,
    mobileMenuState,
    toastState,
    toggleMobileMenu,
    openModal,
    closeModal,
    openPopup,
    closePopup,
    setModalExitOptions,
    openToast,
    closeToast
  };
});
