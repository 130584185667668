<template>
  <div class="profile">
    <AccountInfo
      :user="user"
      :subscription-message="subscriptionMessage"
      class="profile__account"
      @logout="emit('logout')"
    />
    <div class="profile__content">
      <ProfileManager
        class="profile__info"
        :user="user"
        :time-zones="timeZones"
        :selected-time-zone="selectedTimeZone"
        :langs="langs"
        :selected-lang="selectedLang"
        @change-input-value="emit('change-input-value', $event)"
      />
      <PasswordManager
        class="profile__password"
        @change-input-value="emit('change-input-value', $event)"
      />
      <div class="profile__actions">
        <base-button
          :label="$t('profile.cancel')"
          variant="inversed"
          class="profile__action cancel button button-lg"
          @click="handleCancel"
        />
        <base-button
          :label="$t('profile.saveChanges')"
          :disabled="!auth.saveEnabled || auth.isLoading"
          class="profile__action save button button-lg"
          @click="saveChanges"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import AccountInfo from './AccountInfo.vue';
import ProfileManager from './ProfileManager.vue';
import PasswordManager from './PasswordManager.vue';
import { useAuthStore } from '@/stores/AuthStore.js';
import { useRouter } from 'vue-router';
const auth = useAuthStore();
const emit = defineEmits(['change-input-value', 'save', 'logout']);

defineProps({
  user: {
    type: Object,
    required: true
  },
  subscriptionMessage: {
    type: String,
    default: ''
  },
  langs: {
    type: Array,
    default: () => []
  },
  timeZones: {
    type: Array,
    default: () => []
  },
  selectedLang: {
    type: Object
  },
  selectedTimeZone: {
    type: Object
  }
});
const router = useRouter();
const saveChanges = async () => {
  emit('save');
};

const handleCancel = () => {
  auth.resetProfileValues();
  router.push({ name: 'home' });
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;

  &__content {
    flex-grow: 1;
    margin-left: 60px;
    max-width: 470px;
  }

  &__account {
    min-width: 404px;
    padding-right: 60px;
  }

  &__info {
    padding: 20px 0 40px;
    border-bottom: 1px solid $gray-10;
  }

  &__password {
    padding-top: 40px;
  }

  &__actions {
    margin-top: 60px;
    display: flex;
    gap: 16px;
  }

  &__action {
    padding: 15px 8px;
  }
}
</style>
