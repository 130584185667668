import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useErrorsStore = defineStore('ErrorsStore', () => {
  const errors = ref({});

  const setErrors = (newErrors, page_name) => {
    errors.value[page_name] = newErrors;
  };

  return {
    errors,
    setErrors
  };
});
